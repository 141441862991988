@import "src/styles/colors";

.project {
  position: relative;
  padding: 8px;
  margin: 24px 0;
}

.responsive {
  width: 100%;
  max-width: 400px;
  height: auto;
}

.project-image {
  margin-bottom: 24px;
  //display: block;
  //height: 280px;
  //width: auto;
}

.project-desc {
  //max-width: 360px;
  //width: 360px;
}

.project-heading {
  color: $navy;
}

.content {
  width: 400px;
  margin: 8px;
}