@import "src/styles/colors";

.publication {
  position: relative;
  padding: 8px;
  margin: 24px 0;
}


.responsive {
  width: 100%;
  max-width: 400px;
  height: auto;
}

.pub-image {
  margin-bottom: 24px;
  //display: block;
  //height: 280px;
  //width: auto;
}

.content {
  width: 400px;
  margin: 8px;
}

a>h2 {
  color: $navy ;
  text-decoration: none;
  cursor: pointer;
}

a>h2:hover {
  color: $gold;
}

.article-title {
  margin-bottom: 0;
}

.publisher {
  margin-top: 0;
}