//$navy: #0B3B85;
$navy: #09306B;
$navy-light: #4F83D1;
$navy-super-light: #93AFD9;
$navy-bold: #05199C;

//$gold: #CCB22F;
$gold: #BAA22B;
$gold-light: #D5C471;
$gold-super-light: #DDD7B7;

$neutral: rgba(0, 0, 0, 0.55);
$neutral-light: rgba(0, 0, 0, 0.05);
$neutral-dark: rgba(0, 0, 0, 0.81);

$neutral-gray: #bdbdbd

