@import "styles/colors";

.App {
  text-align: center;
}

.section-title {
  margin-bottom: 60px;
}

.projects {
  position: relative;
  margin-bottom: 300px;
}

.about {
  position: relative;
  margin-bottom: 300px;
}

.work {
  position: relative;
  margin-bottom: 300px;
}

.publications {
  position: relative;
  margin-bottom: 300px;
}

.anchors {
  position: fixed;
  top: 16px;
  margin-left: 16px;
  z-index: 999;
}

a > h5 {
  color: $neutral;
  text-decoration: none;
  cursor: pointer;
}

a > h5:hover {
  color: $gold;
}

a > h4:hover {
  color: $gold;
}

h1, h2, h3, h4, h5, h6 {
  color: $navy;
}

p {
  color: $neutral-dark;
}

.footer {
  color: $neutral;
  margin: auto auto 8px auto;
}

.link {
  color: $navy;
  padding: 0;
}

.link:hover{
  color: $gold;
}

.resume{
  //margin-top: -36px;
}


.category-1{
  margin-right:4px;
  background-color: $navy !important;
  color: rgba(255, 255, 255, 0.85) !important;
}

.category-2{
  margin-right:4px;
  background-color: $navy-light !important;

}

.category-3{
  margin-right:4px;
  background-color: $navy-super-light !important;
}

.category-1:hover, .category-2:hover, .category-3:hover{
  //background-color: $gold !important;
}

.skills-legend{
  max-width: 400px;
  margin: 16px auto;
  justify-content: center;
}

.skills-grid{
  max-width: 800px;
  margin: auto;
}
