@import "src/styles/colors";

.skill-chip{
  margin: 4px 2px;
  background-color: $navy-super-light !important;
  //color: white !important;
}

.skill-chip-primary{
  margin: 4px 2px;
  background-color: $navy !important;
  color: rgba(255, 255, 255, 0.85) !important;
}

.skill-chip-secondary{
  margin: 4px 2px;
  background-color: $navy-light !important;
  //color: white !important;
}

.skill-chip:hover, .skill-chip-primary:hover, .skill-chip-secondary:hover{
  //background-color: $gold !important;
  box-shadow: 3px 3px rgba(0, 0, 0, 0.5);
}
