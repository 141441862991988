html{
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


body {
    position: relative;
    background-color: transparent;
    background-image: linear-gradient(to bottom, #90a4b1 0%, #DDD7B7 100%);
    align-items: center;
    justify-content: center;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Major Mono Display', monospace;
}

a {
    text-decoration: none;
    cursor: pointer;
}

::selection {
    background: #D5C471; /* WebKit/Blink Browsers */
}

::-moz-selection {
    background: #D5C471; /* Gecko Browsers */
}
