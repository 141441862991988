@import "src/styles/colors";


.scroll {
  position: relative;
  top: 30vh;
  width: 140px;
  height: auto;
  //letter-spacing: 0.3rem;
  text-align: center;
  //font-size: 2rem;
  color: $navy;
  margin: 0 auto 75vh auto;
}


svg {
  display: block;
  width: 100%;
  height: auto;
  background-color: transparent;
}

.fill-1 {
  fill: $navy;
}

.fill-2 {
  fill: $gold;
}

.fullscreen {
  position: fixed;
  width: 32px;
  height: 32px;
  bottom: 20px;
  right: 20px;
}

@keyframes ball-bounce {
  0%   { transform: translateY(0); }
  25%  { transform: translateY(-25px); }
  100% { transform: translateY(0); }
}

.pointer{
    animation-name: ball-bounce;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-delay: 1s;
}

.title {
  margin-bottom: 40px;
}

.link-icon{
  color: $navy;
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom:16px;
}

.link-icon:hover{
  color: $gold;
}

